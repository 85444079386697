import { axiosInstance } from '../../axios'

export const employeeCallServices = {
  create: (requestModel: any) => {
    return axiosInstance.post(`employee/employee-call/`, requestModel)
  },
  update: (id: string, requestModel: any) => {
    return axiosInstance.put(`employee/employee-call/${id}`, requestModel)
  },
  findAll: async (page: number, limit: number, employeeId: string) => {
    return await axiosInstance.get(`employee/employee-call?page=${page}&limit=${limit}&employeeId=${employeeId}`)
  },
  findEmployeeById: (id: string) => {
    return axiosInstance.get(`employee/employee-call/findByEmployeeId/${id}`)
  },
  remove: (id: string) => {
    return axiosInstance.delete(`employee/employee-call/${id}`)
  },
  call: (dialpadId: string, phoneNumber: string) => {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer Tpfa8GNtsttZ2wwtZJDpXC8y4ye8Gmm6h5spWEPrScweUJNnNSbqRbt7rnt9BM4ejHQmCWmNDGmJdTLN8QcNdhJY48tSZnVD8NLW`,
      },
      body: JSON.stringify({ phone_number: phoneNumber }),
    }

    fetch(`https://dialpad.com/api/v2/users/${dialpadId}/initiate_call`, options)
      .then((response) => response.json())
      .catch((err) => console.error(err))
  },
}
