import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

// Icons
import DefaultLayout from '../../layouts/DefaultLayout'

// Services

import { commonServices } from '../../services/index'

// Components
import LoadingComponent from '../../components/shareds/LoadingComponent'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import { getUser as getUserLocal } from '../../utils/local-storage'

const Tips = () => {
  const [tipsData, setTipsData] = useState<any>()

  const employeeData = getUserLocal()
  const navigate = useNavigate()

  useEffect(() => {
    tipsDataFunction()
  }, [])

  const tipsDataFunction = () => {
    commonServices.tipsFindAll(employeeData.projectId).then((response) => {
      if (response) {
        setTipsData(response.data)
      }
    })
  }

  if (!tipsData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <GeneralCard title='Call List'>
        <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(8, 1fr)' gap={2}>
          <GridItem
            colSpan={6}
            children={
              <Box
                width='100%'
                height='100%'
                overflow='auto'
                dangerouslySetInnerHTML={{
                  __html: tipsData ? tipsData.content : '',
                }}
              />
            }
          />
        </Grid>

        <ToastContainer />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Tips
