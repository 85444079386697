import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

// Icons
import { FaExclamationCircle, FaSms, FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import DefaultLayout from '../../layouts/DefaultLayout'

// Services
import { userServices } from '../../services/users'
import { employeeCallServices } from '../../services/employees/employeeCalls/index'
import { commonServices } from '../../services/index'

// Components
import LoadingComponent from '../../components/shareds/LoadingComponent'
import GeneralCard from '../../components/cards/generalCard/GeneralCard'
import { getUser as getUserLocal } from '../../utils/local-storage'
import { useGlobalContext } from '../../providers/globalProvider'
import CustomInputForm from '../../components/customFormElements/CustomInputForm'
import LiveTable from '../../components/tables/liveTable/InterestingMatcheTable'
import AlertDialogForCallStatus from '../../components/dialog/AlertDialogForCallStatus'
import CustomModal from '../../components/modal/CustomModal'

const Dashboard = () => {
  const { user, logout } = useGlobalContext()
  const [userData, setUsersData] = useState<any[]>([])
  const [messageTemplateData, setMessageTemplateData] = useState<any>()
  const [interestingMatcheData, setInterestingMatcheData] = useState<any>()
  const { isOpen: isOpenInformation, onOpen: onOpenInformation, onClose: onCloseInformation } = useDisclosure()
  const employeeData = getUserLocal()
  const navigate = useNavigate()

  const handleClick = () => {
    logout()
    navigate('/login')
  }

  useEffect(() => {
    console.log(employeeData)
    userDataFunction()
    messageTemplateDataFunction()
    interestingMatcheDataFunction()
  }, [])

  const userDataFunction = () => {
    employeeCallServices.findAll(1, 200, employeeData.sub).then((response) => {
      if (response) {
        setUsersData(response.data.items)
      }
    })
    onCloseInformation()
  }

  const messageTemplateDataFunction = () => {
    commonServices.messageTemplateFindAll('8c115a5d-749b-4f0f-9603-b05d2049fa19').then((response) => {
      if (response) {
        setMessageTemplateData(response.data)
      }
    })
  }
  const interestingMatcheDataFunction = () => {
    commonServices.interestingMatcheFindAll('8c115a5d-749b-4f0f-9603-b05d2049fa19').then((response) => {
      if (response) {
        setInterestingMatcheData(response.data)
      }
    })
  }

  const messageSend = async (employeeCallId: string) => {
    const resquestModel = { employeeCallId: employeeCallId }
    onOpenInformation()
    await commonServices.userMessageCreate(resquestModel)
    userDataFunction()
  }

  const mailSend = async (employeeCallId: string) => {
    const resquestModel = { employeeCallId: employeeCallId }
    onOpenInformation()
    await commonServices.userEmailCreate(resquestModel)
    userDataFunction()
  }

  const callCustomer = async (dialpadId: string, phoneNumber: string) => {
    const plusNumber = '+' + phoneNumber
    await employeeCallServices.call(dialpadId, plusNumber)
  }

  const callStatusUpdate = async (userId: string, status: string) => {
    const resquestModel = { callStatus: status }
    await userServices.updateStatus(userId, resquestModel)
    userDataFunction()
  }

  const handleOnSubmit = (id: any) => {
    navigate(`/user/${id}`)
  }

  if (!userData) {
    return <LoadingComponent />
  }

  if (!messageTemplateData) {
    return <LoadingComponent />
  }
  if (!interestingMatcheData) {
    return <LoadingComponent />
  }

  return (
    <DefaultLayout>
      <GeneralCard title='Call List'>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(8, 1fr)' gap={2}>
          <GridItem colSpan={6} rowSpan={2}>
            <TableContainer maxH={'40em'} overflowY={'auto'}>
              <Table variant='simple' size={'sm'}>
                <Thead bg={'white'} position={'sticky'} top={'0px'} borderRadius={'10px'} zIndex={1}>
                  <Tr>
                    <Th children={'NAME'} />
                    <Th children={'USER NAME'} />
                    <Th children={'PHONE NUMBER '} />
                    <Th children={'SMS'} />
                    <Th children={'EMAIL'} />
                    <Th children={'CALL'} />
                    <Th children={'CALL STATUS'} />
                  </Tr>
                </Thead>
                {userData.map((item) => {
                  return (
                    <Tbody paddingBottom={'20px'} key={item.id}>
                      <Tr>
                        <Td children={item.name} />
                        <Td children={item.userName} />
                        <Td children={item.phoneNumber} />
                        <Td
                          children={
                            <>
                              {item.isSendSms ? (
                                <Button
                                  key={item.phoneNumber}
                                  colorScheme='green'
                                  disabled={true}
                                  children={<Icon as={FaSms} />}
                                />
                              ) : (
                                <Button
                                  colorScheme='yellow'
                                  size={'xs'}
                                  onClick={() => messageSend(item.id)}
                                  children='Send'
                                />
                              )}
                            </>
                          }
                        />
                        <Td
                          children={
                            <>
                              {item.isSendMail ? (
                                <Button
                                  colorScheme='green'
                                  disabled={true}
                                  _hover={{}}
                                  children={<Icon as={FaSms} />}
                                />
                              ) : (
                                <Button
                                  colorScheme='yellow'
                                  size={'xs'}
                                  onClick={() => mailSend(item.id)}
                                  children='Send'
                                />
                              )}
                            </>
                          }
                        />
                        <Td
                          children={
                            <Button
                              colorScheme='blue'
                              size={'xs'}
                              onClick={() => callCustomer(item.dialpadEmployeeId, item.phoneNumber)}
                              children='Call'
                            />
                          }
                        />
                        <Td
                          children={
                            <>
                              <AlertDialogForCallStatus
                                confirmAction={callStatusUpdate}
                                id={item.userId}
                                status='negative'
                                buttonColor='red'
                                icon={FaTimesCircle}
                              />
                              <AlertDialogForCallStatus
                                confirmAction={callStatusUpdate}
                                id={item.userId}
                                status='notAnswered'
                                buttonColor='grey'
                                icon={FaExclamationCircle}
                              />
                              <AlertDialogForCallStatus
                                confirmAction={callStatusUpdate}
                                id={item.userId}
                                status='positive'
                                buttonColor='green'
                                icon={FaCheckCircle}
                              />
                            </>
                          }
                        />
                      </Tr>
                    </Tbody>
                  )
                })}

                <Tfoot paddingTop={'20px'} bg={'white'} borderRadius={'10px'} />
              </Table>
            </TableContainer>
          </GridItem>
          <CustomInputForm
            title='Script Text'
            customColSpan={2}
            children={
              <Box
                width='100%'
                height='19rem'
                overflow='auto'
                dangerouslySetInnerHTML={{
                  __html: messageTemplateData ? messageTemplateData.content : '',
                }}
                overflowY='auto'
              />
            }
          />
          <GridItem
            colSpan={2}
            rowSpan={1}
            children={<LiveTable interestingMatcheData={interestingMatcheData} key={'asdawerer'} />}
          />
        </Grid>

        <ToastContainer />
        <CustomModal
          title=''
          isOpen={isOpenInformation}
          onClose={onCloseInformation}
          key={'Confirmation'}
          size={'md'}
          children={<Text textAlign={'center'} children={'Transaction in Progress'} />}
        />
      </GeneralCard>
    </DefaultLayout>
  )
}

export default Dashboard
