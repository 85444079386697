import { ReactNode } from 'react'
import { Box, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import SearchBar from './SearchBar'

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <SearchBar onOpen={onOpen} />
      <Box minH='100vh' bg={useColorModeValue('white.100', 'white.900')}>
        <Box ml={{ base: 0, md: 0 }} p='0' children={children} />
      </Box>
    </>
  )
}
