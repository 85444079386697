import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { IconType } from 'react-icons'

interface CustomAlertDialogProps {
  confirmAction: (id: string, status: string) => void
  id: string
  status: string
  buttonColor: string
  icon: IconType
}

const AlertDialogForCallStatus: React.FC<CustomAlertDialogProps> = ({
  confirmAction,
  id,
  status,
  buttonColor,
  icon,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Button size={'sm'} onClick={onOpen} bg={buttonColor} children={<Icon as={icon} />} mr={'5'} />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' children={'Change Status'} />

            <AlertDialogBody>
              Are you sure? You can't undo this <Text as='span' color={buttonColor} children={status.toUpperCase()} />{' '}
              action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                bg={'red'}
                onClick={() => {
                  confirmAction(id, status)
                  onClose()
                }}
                children={'Ok'}
              />
              <Button ref={cancelRef} onClick={onClose} ml={3} children={'Cancel'} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AlertDialogForCallStatus
