import { withLoggedIn, withLoggedOut } from './withLoggedIn'
import AccessDenied from '../pages/error/AccessDenied'
import { Route, Routes } from 'react-router-dom'
import _404 from '../pages/error/404'
import Dashboard from '../pages/dashboard/dashboard'
import Tips from '../pages/setting/tips'

import Login from '../pages/auth/login'

export const AuthRoute = (...args: Parameters<typeof Route>) => Route(...args)

export function AppRoutes() {
  return (
    <Routes>
      <Route path='/' element={withLoggedIn(Dashboard)()} />
      <Route path='/login' element={withLoggedOut(Login)()} />

      <Route path='/dashboard' element={withLoggedIn(Dashboard)()} />
      <Route path='/tips' element={withLoggedIn(Tips)()} />

      <Route path='/access-denied' element={withLoggedIn(AccessDenied)()} />
      <Route path='*' element={withLoggedIn(Dashboard)()} />
    </Routes>
  )
}
