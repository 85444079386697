import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

// Services
interface MainTableProps {
  interestingMatcheData: any[]
}

export default function InterestingMatcheTable(props: MainTableProps) {
  const navigate = useNavigate()

  useEffect(() => {}, [props.interestingMatcheData])

  const handleOnSubmit = (id: any) => {
    navigate(`/user/${id}`)
  }

  const userData = props.interestingMatcheData
  return (
    <TableContainer maxH='19em' overflowY='auto'>
      <Table variant='simple' size={'sm'}>
        <Thead bg={'white'} position={'sticky'} top={'0px'} borderRadius={'10px'}>
          <Tr>
            <Th children={'Home Team'} />
            <Th children={'Away Team'} />
            <Th children={'1'} w={5} />
            <Th children={'X'} w={5} />
            <Th children={'2'} w={5} />
          </Tr>
        </Thead>
        {userData.map((item) => {
          return (
            <Tbody paddingBottom={'20px'} key={item.id}>
              <Tr>
                <Td children={item.homeTeam} />
                <Td children={item.awayTeam} />
                <Td children={item.homeTeamOdds} w={5} />
                <Td children={item.drawOdds} w={5} />
                <Td children={item.awayTeamOdds} w={5} />
              </Tr>
            </Tbody>
          )
        })}

        <Tfoot paddingTop={'20px'} bg={'white'} borderRadius={'10px'} />
      </Table>
    </TableContainer>
  )
}
