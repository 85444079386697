import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'

interface ModalProps {
  children: any
  title: string
  isOpen: boolean
  onClose: () => void
  size?: string
}

const CustomModal: React.FC<ModalProps> = ({ children, title, isOpen, onClose, size }) => {
  return (
    <Modal size={size ? size : '3xl'} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay backdropFilter='blur(10px) hue-rotate(90deg)' />
      <ModalContent>
        <>
          {title ? (
            <ModalHeader borderBottom='1px solid #2B6CB0' color={'#2B6CB0'} children={title} />
          ) : (
            <ModalHeader />
          )}
        </>
        <ModalCloseButton />
        <ModalBody children={children} />
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}

export default CustomModal
