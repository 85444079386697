import { axiosInstance } from './axios/index'

export const commonServices = {
  messageTemplateFindAll: (id: string) => {
    return axiosInstance.get(`message-templates/findByProjectIdAndType/${id}/${'script'}`)
  },
  tipsFindAll: (id: string) => {
    return axiosInstance.get(`message-templates/findByProjectIdAndType/${id}/${'tips'}`)
  },
  interestingMatcheFindAll: (projectId: string) => {
    return axiosInstance.get(`interesting-matches/findByProjectId/${projectId}`)
  },
  userEmailCreate: (requestModel: any) => {
    return axiosInstance.post('user-emails', requestModel)
  },
  userMessageCreate: (requestModel: any) => {
    return axiosInstance.post('user-messages', requestModel)
  },
  userNoteCreate: (requestModel: any) => {
    return axiosInstance.post('user-notes', requestModel)
  },
}
