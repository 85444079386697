import axios from 'axios'
import { getToken as getTokenLocal } from '../../utils/local-storage'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

export const axiosInstance = axios.create({
  baseURL: 'https://callcenterapi.tokenhouse.systems/',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getTokenLocal()}`,
  },
})

axiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response
  },
  (error) => {
    toast(error.response.data.message)
    // Do something with response error
    // You can even test for specific status codes here

    // Return the error to the caller
    return Promise.reject(error)
  },
)
