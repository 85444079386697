import {
  useColorModeValue,
  MenuDivider,
  IconButton,
  MenuButton,
  FlexProps,
  MenuItem,
  MenuList,
  HStack,
  VStack,
  Flex,
  Menu,
  Text,
  Box,
  Icon,
} from '@chakra-ui/react'
import { useGlobalContext } from '../providers/globalProvider'
import { FiChevronDown, FiMenu } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import { getUser as getUserLocal } from '../utils/local-storage'

import { FcAssistant } from 'react-icons/fc'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const SearchBar = ({ onOpen, ...rest }: MobileProps) => {
  const { user, logout } = useGlobalContext()
  const userData = getUserLocal()
  const navigate = useNavigate()

  const handleClick = () => {
    logout()
    navigate('/login')
  }
  const navigateFunction = (url: string) => {
    navigate(`/${url}`)
  }

  return (
    <Flex
      px={{ base: 4, md: 4 }}
      height='20'
      alignItems='center'
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      {...rest}
    >
      <HStack
        spacing={{ base: '0', md: '4' }}
        justifyContent={{ base: 'space-between', md: 'space-between' }}
        width='100%'
      >
        <Text
          textAlign={'left'}
          fontSize='2xl'
          fontFamily='monospace'
          fontWeight='bold'
          color='red'
          children={'Pix55'}
          onClick={() => navigateFunction('dashboard')}
        />
        <HStack spacing={{ base: '0', md: '6' }} justifyContent={{ base: 'flex-end', md: 'flex-end' }}>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton py={2} transition='all 0.3s' _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Icon as={FcAssistant} />
                  <VStack display={{ base: 'none', md: 'flex' }} alignItems='flex-start' spacing='1px' ml='2'>
                    <Text fontSize='sm' children={userData.name} />
                    <Text fontSize='xs' color='gray.600' children={'Agent'} />
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }} children={<FiChevronDown />} />
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem children={'Tips'} onClick={() => navigateFunction('tips')} />
                <MenuItem children={'Settings'} onClick={() => navigateFunction('setting')} />
                <MenuDivider />
                <MenuItem onClick={handleClick} children={'Sign out'} />
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </HStack>
    </Flex>
  )
}

export default SearchBar
